import { DealPostType } from '@types';

import { formatPrice } from './formatUtils';

type ExtraDealsType = Array<{
  label: string;
  value: string;
  amount: number;
}>;

export const getExtraDeals = (deals: DealPostType[]) => {
  let extraDeals: ExtraDealsType = [];

  if (deals) {
    extraDeals = processDeals(deals, extraDeals);
    extraDeals = sortExtraDeals(extraDeals);
  }

  return extraDeals;
};

const addOrUpdateExtraDeal = (
  extraDeals: ExtraDealsType,
  deal: DealPostType
): ExtraDealsType => {
  const existingDealIndex = extraDeals.findIndex(
    (x) => x.value === deal.extraDealLink
  );

  if (existingDealIndex === -1) {
    // Return a new array with the added deal
    extraDeals.push({
      label: deal.extraDeal,
      value: deal.extraDealLink,
      amount: 1
    });
  } else {
    // Return array with the updated deal
    // eslint-disable-next-line no-param-reassign
    extraDeals[existingDealIndex].amount += 1;
  }

  return extraDeals;
};

const processDeals = (deals: DealPostType[], extraDeals: ExtraDealsType) => {
  for (let i = 0; i < deals.length; i++) {
    const deal = deals[i];
    if (deal.extraDealLink) {
      // eslint-disable-next-line no-param-reassign
      extraDeals = addOrUpdateExtraDeal(extraDeals, deal);
    }
  }
  return extraDeals;
};

const sortExtraDeals = (extraDeals: ExtraDealsType) => {
  return extraDeals.sort((a, b) => b.amount - a.amount);
};

export const getBrandStringFromBrands = (brands: string[]) => {
  // if there are more than three brands, show the first three and add a plus sign
  if (brands.length > 3) {
    return `${brands.slice(0, 3).join(', ')} +${brands.length - 3} more`;
  }

  // if one brand just return the brand
  if (brands.length === 1) {
    return brands[0];
  }

  // if two or three brands, return them all
  return brands.join(', ');
};

type CouponType = Array<{
  label: string;
  value: string;
  amount: number;
  brands: string[];
}>;

export const getCoupons = (deals: DealPostType[]) => {
  let coupons: CouponType = [];

  if (deals) {
    coupons = processDealsForCoupons(deals);
    coupons = sortAndFilterCoupons(coupons);
  }

  return coupons;
};

const processDealsForCoupons = (deals: DealPostType[]): CouponType => {
  const coupons: CouponType = [];

  for (let i = 0; i < deals.length; i++) {
    const deal = deals[i];
    if (deal.promoCode && isPromoCodeValid(deal.promoCode)) {
      const existingCouponIndex = findCouponIndex(coupons, deal.promoCode);

      if (existingCouponIndex !== -1) {
        coupons[existingCouponIndex] = updateCoupon(
          coupons[existingCouponIndex],
          deal
        );
      } else {
        coupons.push(createNewCoupon(deal));
      }
    }
  }

  return coupons;
};

const isPromoCodeValid = (promoCode: string | null) => {
  return promoCode !== null && promoCode !== '';
};

const findCouponIndex = (coupons: CouponType, promoCode: string) => {
  return coupons.findIndex((coupon) => coupon.value === promoCode);
};

const updateCoupon = (
  coupon: CouponType[number],
  deal: DealPostType
): CouponType[number] => {
  return {
    ...coupon,
    amount: coupon.amount + 1,
    brands:
      deal.brand && !coupon.brands.includes(deal.brand)
        ? [...coupon.brands, deal.brand]
        : coupon.brands
  };
};

const createNewCoupon = (deal: DealPostType): CouponType[number] => {
  const dealBrand = deal.brand ? [deal.brand] : [];

  return {
    label: `${deal.couponFixed ? formatPrice(deal.couponFixed) : ''}${
      deal.couponPercentage ? `${deal.couponPercentage}%` : ''
    }`,
    value: deal.promoCode!,
    amount: 1,
    brands: dealBrand
  };
};

const sortAndFilterCoupons = (coupons: CouponType) => {
  return coupons
    .sort((a, b) => b.amount - a.amount)
    .filter((coupon) => coupon.amount > 1);
};

export const getBrands = (deals: DealPostType[]) => {
  const brands: Array<{
    label: string;
    value: string;
    amount: number;
  }> = [];

  if (deals) {
    for (let i = 0; i < deals.length; i++) {
      const deal = deals[i];
      if (deal.brand) {
        // if does not exist in extra deals then add it in
        if (!brands.find((x) => x.value === deal.brand)) {
          brands.push({
            label: deal.brand,
            value: deal.brand,
            amount: 1
          });
        } else {
          // if it exists then increment the amount
          const index = brands.findIndex((x) => x.value === deal.brand);
          brands[index].amount += 1;
        }
      }
    }
  }

  // order extraDeals by amount desc
  brands.sort((a, b) => b.amount - a.amount);

  return brands;
};

export const getPromotions = (deals: DealPostType[]) => {
  const promotions: Array<{
    // this will be the link
    label: string;
    // this will be the promotion name
    value: string;
    // this will be how many of them
    amount: number;
  }> = [];

  if (deals) {
    for (let i = 0; i < deals.length; i++) {
      const deal = deals[i];
      if (deal.extraDealLink && deal.extraDeal) {
        // if does not exist in extra deals then add it in
        if (!promotions.find((x) => x.value === deal.extraDealLink)) {
          promotions.push({
            label: deal.extraDeal,
            value: deal.extraDealLink,
            amount: 1
          });
        } else {
          // if it exists then increment the amount
          const index = promotions.findIndex(
            (x) => x.value === deal.extraDealLink
          );
          promotions[index].amount += 1;
        }
      }
    }
  }

  // order extraDeals by amount desc
  promotions.sort((a, b) => b.amount - a.amount);

  return promotions;
};

export const getHowManyExtraDealsByExtraDealLink = (
  deals: DealPostType[],
  extraDealLink: string
) => {
  const extraDeals = getExtraDeals(deals);
  const extraDeal = extraDeals.find((x) => x.value === extraDealLink);
  return extraDeal ? extraDeal.amount : 0;
};

export const getHowManyDealsByBrand = (
  deals: DealPostType[],
  brand: string
) => {
  const brands = getBrands(deals);
  const brandItem = brands.find((x) => x.value === brand);
  return brandItem ? brandItem.amount : 0;
};

export const getHowManyDealsByPromoCode = (
  deals: DealPostType[],
  promoCode: string
) => {
  const coupons = getCoupons(deals);
  const couponItem = coupons.find((x) => x.value === promoCode);
  return couponItem ? couponItem.amount : 0;
};
